import React, { FC } from 'react';
import styled from 'styled-components';
import ReactCountdown from 'react-countdown';

import Text from './Text';
import { mobile, smMobile, useQuery } from 'styles/breakpoints';

interface CountdownProps {
  only?: string;
  perDay?: string;
  validFor?: string;
  days?: string;
  hours?: string;
  minutes?: string;
  seconds?: string;
  date?: number;
  bgColor?: string;
  hideHours?: boolean;
  hideLowerText?: boolean;
  textColor?: string;
  fontFamily?: string;
}

const Container = styled.div<{ bgColor?: string }>`
  background-color: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.colors.lightGreen};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  flex: 1;
  max-width: 71.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const RightContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
`;

const TextStyled = styled(Text)<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
`;

const UpperText = styled(TextStyled)<{ fontFamily?: string }>`
  -webkit-text-size-adjust: 100%;
  @media ${smMobile} {
    font-size: 0.875rem;
  }
`;

const LowerText = styled(TextStyled)`
  margin-top: 0.5rem;
  -webkit-text-size-adjust: 100%;
`;

const Underlined = styled.span`
  text-decoration: underline;
  font-size: ${({ theme }) => theme.typography.h2.fontSize}rem;
  line-height: ${({ theme }) => theme.typography.h2.lineHeight}rem;

  @media ${mobile} {
    font-family: ${({ theme, fontFamily }) =>
      fontFamily ? fontFamily : theme.typography.h2.fontFamily};
    font-size: ${({ theme }) => theme.typography.tabActive.fontSize}rem;
    line-height: ${({ theme }) => theme.typography.tabActive.lineHeight}rem;
  }
  @media ${smMobile} {
    font-size: 0.875rem;
  }
`;

const ReactCountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const Time = styled(TextStyled)``;
const Colon = styled(TextStyled)`
  margin: 0 0.25rem;
`;

const CountdownUnitsContainer = styled.div<{ hasMarginLeft?: boolean }>`
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '0.625rem' : '0')};
`;

const Unit = styled(TextStyled)`
  margin-top: 0.5rem;
`;

const Countdown: FC<CountdownProps> = ({
  only,
  perDay,
  validFor,
  days,
  hours,
  minutes,
  seconds,
  date = Date.now() + 900000,
  bgColor,
  hideHours,
  hideLowerText,
  textColor = '#000000',
}) => {
  const { isMobile } = useQuery();

  const renderColon = () => (
    <Colon textColor={textColor} type={isMobile ? 'h4' : 'countS'}>
      :
    </Colon>
  );

  const renderer = ({
    formatted = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    },
  }) => (
    <ReactCountdownContainer>
      {hideHours ? null : (
        <>
          <CountdownUnitsContainer>
            <Time textColor={textColor} type={isMobile ? 'h4' : 'countS'}>
              {formatted.hours}
            </Time>
            <Unit textColor={textColor} type={isMobile ? 'bodyXS' : 'bodyM'}>
              {hours}
            </Unit>
          </CountdownUnitsContainer>
          {renderColon()}
        </>
      )}
      <CountdownUnitsContainer>
        <Time textColor={textColor} type={isMobile ? 'h4' : 'countS'}>
          {formatted.minutes}
        </Time>
        <Unit textColor={textColor} type={isMobile ? 'bodyXS' : 'bodyM'}>
          {minutes}
        </Unit>
      </CountdownUnitsContainer>
      {renderColon()}
      <CountdownUnitsContainer>
        <Time textColor={textColor} type={isMobile ? 'h4' : 'countS'}>
          {formatted.seconds}
        </Time>
        <Unit textColor={textColor} type={isMobile ? 'bodyXS' : 'bodyM'}>
          {seconds}
        </Unit>
      </CountdownUnitsContainer>
    </ReactCountdownContainer>
  );

  const countdownDate = date;

  return (
    <Container bgColor={bgColor}>
      <ContentContainer>
        <LeftContent>
          <UpperText
            textColor={textColor}
            type={isMobile ? 'tabActive' : 'h2600'}
          >
            {only} <Underlined>{perDay}</Underlined>
          </UpperText>
          {hideLowerText ? null : (
            <LowerText
              textColor={textColor}
              type={isMobile ? 'bodyM' : 'h3400'}
            >
              {validFor}
            </LowerText>
          )}
        </LeftContent>
        <RightContent>
          <ReactCountdown date={countdownDate} renderer={renderer} />
        </RightContent>
      </ContentContainer>
    </Container>
  );
};

export default Countdown;
